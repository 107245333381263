
import React from 'react'
import AgoraRTC from "agora-rtc-sdk-ng";
import {connect} from 'react-redux'
import {Button,IconButton} from '@mui/material'
import VideoCallIcon from '@mui/icons-material/VideoCall';
import "./EventDetail.scss"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios'
import CachedIcon from '@mui/icons-material/Cached';
import ReplayIcon from '@mui/icons-material/Replay';
//var val = Math.floor(1000 + Math.random() * 9000);
let rtc = {
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
};





function LiveStream(props) {
    const [joined,setJoined]=React.useState(0)
    const [liveStream,setLiveStream]=React.useState(false)
    const [val,setVal]=React.useState(null)
    const refContainer = React.useRef(null)
    const [options,setOptions]=React.useState({})
    const buttonRef = React.useRef(null)
    
    React.useEffect(()=>{
        let uid = Math.floor(1000 + Math.random() * 9000)
        setVal(uid)
        let joinUser = null
            
        setTimeout(() => {
            if(buttonRef && props.joinEvent){
                buttonRef.current.click()
            }
            
        }, 1000);        
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/access_token?channelName=test&role=${props.eventOrganizerId===props.user.userInfo._id?'publisher':'subscriber'}&uid=${uid}`)
        .then(res=>{
            console.log(res)
            setOptions(
                {
                    // Pass your app ID here.
                    appId: "f5808036f38f4f46897775f9567caac5",
                    // Set the channel name.
                    channel: "test",
                    // Use a temp token
                    token: res.data.token, 
                    //"007eJxTYNhyZJ6xqeHFZE/9XZ19rD5rG7WjHmxinxznc3DL5k4XnyIFhjRTCwMLA2OzNGOLNJM0EzMLS3Nzc9M0S1Mz8+TExGTTZRzqyapRGslazywZGRkgEMTnZihJLS5JzkjMy0vNYWAAALYHIJA=",
                    // Uid
                    uid,
                }
            )
        })

    },[])
    console.log(refContainer,options)
    async function startBasicLiveStreaming() {
        rtc.client = AgoraRTC.createClient({mode: "live", codec: "vp8"});
    
        window.onload = async function () {
            document.getElementById("host-join").onclick = async function () {
                setLiveStream(true)
                
                rtc.client.setClientRole("host");
                await rtc.client.join(options.appId, options.channel, options.token, options.uid);
                // Create an audio track from the audio sampled by a microphone.
                rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                // Create a video track from the video captured by a camera.
                rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                // Publish the local audio and video tracks to the channel.
                await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
                // Dynamically create a container in the form of a DIV element for playing the remote video track.
                const localPlayerContainer = document.createElement("div");
                // Specify the ID of the DIV container. You can use the `uid` of the remote user.
                localPlayerContainer.id = options.uid;
                localPlayerContainer.textContent = "Local user " + options.uid;
                localPlayerContainer.style.width = "640px";
                localPlayerContainer.style.height = "480px";
                //document.body.append(localPlayerContainer);
                console.log(refContainer)
                
                rtc.localVideoTrack.play(refContainer.current);
                await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/event/remind`,{eventId:props.eventId,message:`${props.name} has started live stream for ${props.eventName}`},{headers:{token:props.user.user}})
    
                console.log("publish success!");
            };

    
            document.getElementById("audience-join").onclick = async function () {
                setLiveStream(true)
                
                rtc.client.setClientRole("audience");
                console.log('inside join')
                const joioinfo = await rtc.client.join(options.appId, options.channel, options.token, options.uid);
                console.log('inside join1---------------------------------------',joioinfo)
                rtc.client.on("user-published", async (user, mediaType) => {
                    console.log('inside join2---------------------------------------')
                    // Subscribe to a remote user.
                    await rtc.client.subscribe(user, mediaType);
                    console.log("subscribe success");
                    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/event/liveCount`,{eventId:props.eventId,set:"inc"},{headers:{token:props.user.user}})
    
                    // If the subscribed track is video.
                    if (mediaType === "video") {
                        // Get `RemoteVideoTrack` in the `user` object.
                        const remoteVideoTrack = user.videoTrack;
                        // Dynamically create a container in the form of a DIV element for playing the remote video track.
                        const remotePlayerContainer = document.createElement("div");
                        // Specify the ID of the DIV container. You can use the `uid` of the remote user.
                        remotePlayerContainer.id = user.uid.toString();
                        remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
                        remotePlayerContainer.style.width = "640px";
                        remotePlayerContainer.style.height = "480px";
                        //document.body.append(remotePlayerContainer);
    
                        // Play the remote video track.
                        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
                        remoteVideoTrack.play(refContainer.current);
    
                        // Or just pass the ID of the DIV container.
                        // remoteVideoTrack.play(playerContainer.id);
                    }
    
                    // If the subscribed track is audio.
                    if (mediaType === "audio") {
                        // Get `RemoteAudioTrack` in the `user` object.
                        const remoteAudioTrack = user.audioTrack;
                        // Play the audio track. No need to pass any DOM element.
                        remoteAudioTrack.play();
                    }
                });
    
                rtc.client.on("user-unpublished", user => {
                    // Get the dynamically created DIV container.
                    const remotePlayerContainer = document.getElementById(user.uid);
                    // Destroy the container.
                    remotePlayerContainer.remove();
                });
            };
    
            document.getElementById("leave").onclick = async function () {
                setLiveStream(false)
                await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/event/liveCount`,{eventId:props.eventId,set:"dec"},{headers:{token:props.user.user}})
                window.location.reload(false);
                //setVal(Math.floor(1000 + Math.random() * 9000))
                // Close all the local tracks.
                rtc.localAudioTrack.close();
                rtc.localVideoTrack.close();
                // Traverse all remote users.
                rtc.client.remoteUsers.forEach(user => {
                    // Destroy the dynamically created DIV containers.
                    const playerContainer = document.getElementById(user.uid);
                    playerContainer && playerContainer.remove();
                });
    
                // Leave the channel.
                await rtc.client.leave();
                if(props.eventOrganizerId===props.user.userInfo._id){
                    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/event/remind`,{eventId:props.eventId,message:`${props.name} has stopped the live stream for ${props.eventName}`},{headers:{token:props.user.user}})
                    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/event/liveCount`,{eventId:props.eventId,set:"zero"},{headers:{token:props.user.user}})
                }
                
            };
        };
    }
    
    Object.keys(options).length>0&&startBasicLiveStreaming();

    console.log(document,buttonRef,props)

    const renderOrg = ()=>{
        return <div>
        {props.eventOrganizerId===props.user.userInfo._id?<>
         {liveStream?<h2 class="left-align">Hey, {props.name} You are Live !</h2>:<h2 class="left-align">Hey, {props.name} You can stream this event !</h2>}
         </>:<></>}
         <p>Note : Starting a live stream will result in opening of cam. this stream will be visible to all your subscribers if they join as an audience to this event</p>
         {props.eventOrganizerId===props.user.userInfo._id&&<p>{joined===0?0:joined-1} people have joined the event
         <IconButton onClick={()=>{
            axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/event/liveCount/${props.eventId}`,{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                setJoined(res.data.result)
            })
         }}>
            <CachedIcon />
         </IconButton>
         </p>}
         <Button  disabled={liveStream?true:false} startIcon={<VideoCallIcon />} id="host-join" variant="contained">Start Live Stream</Button>
         <IconButton onClick={()=>{
            window.location.reload(true);
         }}>
            <ReplayIcon />
         </IconButton>
         <Button ref={buttonRef}  sx={{display:"none"}} id="audience-join" variant="contained">Join</Button>
         <Button sx={{visibility:liveStream?"visible":"hidden"}}startIcon={<ExitToAppIcon />} id="leave" variant="outlined">Stop Live Stream</Button>

         
         </div>
    }

  return (
    <>
    {(props.user.userInfo&&props.eventOrganizerId) &&<div>
        {liveStream&&<div className="live-stream-div" ref={refContainer} />}
         {props.eventOrganizerId===props.user.userInfo._id?
        renderOrg()
         :
         <div>
         <div>
         {liveStream?<h2 class="left-align">Hey, {props.user.userInfo.name} You are streaming, enjoy !</h2>:<h2 class="left-align">Hey, {props.user.userInfo.name} You can stream this event !</h2>}
         <Button sx={{display:"none"}} id="host-join" variant="contained">Start Live Stream</Button>
         <Button ref={buttonRef} disabled={liveStream?true:false} id="audience-join" variant="contained">Join</Button>
         <IconButton onClick={()=>{
            window.location.reload(true);
         }}>
            <ReplayIcon />
         </IconButton>
         <Button sx={{visibility:liveStream?"visible":"hidden"}}startIcon={<ExitToAppIcon />} id="leave" variant="outlined">Stop Live Stream</Button>

         
         </div>
        </div>}
    </div>}
    </>
  )
}

const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

export default connect(mapStateToProps)(LiveStream)