import React, { useState } from 'react'
import AgoraUIKit, { layout } from 'agora-react-uikit'
import 'agora-react-uikit/dist/index.css'
import {connect} from 'react-redux'

const Live = (props) => {
    const [videocall, setVideocall] = useState(true)
    const [isHost, setHost] = useState(true)
    const [isPinned, setPinned] = useState(false)
    const [username, setUsername] = useState('')

    React.useEffect(()=>{
      if(props.location.state.organiserId!==props.user.userInfo._id){
        setHost(false)
      }
    },[])
  
    return (
      <div style={styles.container}>
        <div style={styles.videoContainer}>
          {/* <h1 style={styles.heading}>Agora React Web UI Kit</h1> */}
          {videocall ? (
            <>
              <div style={styles.nav}>
                <h3 className="p-3">
                  {
                    props.location.state.organiserId!==props.user.userInfo._id?"You have joined the stream":"You're streaming the event"
                  }
                </h3>
                {/* <p style={styles.btn} onClick={() => setHost(!isHost)}>
                  Change Role
                </p>
                <p style={styles.btn} onClick={() => setPinned(!isPinned)}>
                  Change Layout
                </p> */}
              </div>
              <AgoraUIKit
                rtcProps={{
                  appId: 'f5808036f38f4f46897775f9567caac5',
                  channel: 'test',
                  token: '007eJxTYJhnsynRhHu/3fvfcY/LHT/u2np2neZPVx4XoZ2O/9btl/JQYEgztTCwMDA2SzO2SDNJMzGzsDQ3NzdNszQ1M09OTEw2Nd/6IaUhkJEh7LsiIyMDBIL4LAwlqcUlDAwAhbwgKw==', // add your token if using app in secured mode
                  role: isHost ? 'host' : 'audience',
                  layout: isPinned ? layout.pin : layout.grid,
                  enableScreensharing: false
                }}
                // styleProps={{c}}
                // rtmProps={{ username: username || 'user', displayUsername: true }}
                callbacks={{
                  EndCall: () => {
                    setVideocall(false)
                    props.history.push("/dashboard")
                    window.location.reload(false);
                  }
                }}
              />
            </>
          ) : (
            null
            // <div style={styles.nav}>
            //   <input
            //     style={styles.input}
            //     placeholder='nickname'
            //     type='text'
            //     value={username}
            //     onChange={(e) => {
            //       setUsername(e.target.value)
            //     }}
            //   />
            //   <h3 style={styles.btn} onClick={() => setVideocall(true)}>
            //     Start Call
            //   </h3>
            // </div>
          )}
        </div>
      </div>
    )
};
const styles = {
    container: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flex: 1,
      backgroundColor: '#ebd8ff'
    },
    heading: { textAlign: 'center', marginBottom: 0 },
    nav: { display: 'flex', justifyContent: 'space-around' },
    input: { display: 'flex', height: 24, alignSelf: 'center' },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      },
    btn: {
      backgroundColor: '#5a1e96',
      cursor: 'pointer',
      borderRadius: 5,
      padding: '4px 8px',
      color: '#ffffff',
      fontSize: 20
    },
  }


  const mapStateToProps = ({EventUser})=>{
    return {
      user:EventUser
    }
  }

export default connect(mapStateToProps)(Live);