import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import "./PrivacyPolicy.scss"
function FAQ() {
    const containerStyle = {
        padding:"2%"
      };
    
  return (
    <div >
        <Header/>
        <div  style={containerStyle}>
        <div>
      <h2>Frequently Asked Questions</h2>
      <ol>
        <li>
          <strong>What is EventPickl?</strong><br />
          EventPickl is a platform that connects event organizers with vendors and services they need to plan and execute events.
        </li>
        <li>
          <strong>How do I create an event using EventPickl?</strong><br />
          Log in to your EventPickl organizer account and use the "Create Event" option to fill in event details.
        </li>
        <li>
          <strong>Can I create both public and private events?</strong><br />
          Yes, you can specify the privacy settings for your event, making it public or private.
        </li>
        <li>
          <strong>How can I invite attendees to my private event?</strong><br />
          You can invite attendees to your private event by sharing the event link with them directly.
        </li>
        <li>
          <strong>How do I find vendors for my event?</strong><br />
          You can find vendors for your event by posting your event on EventPickl, and vendors interested in your event type can place bids.
        </li>
        <li>
          <strong>What is the bidding system, and how does it work?</strong><br />
          The bidding system allows vendors to place bids on your event with their pricing and services. You can review these bids and select the ones that best fit your needs.
        </li>
        <li>
          <strong>How can I contact the event organizer or vendors?</strong><br />
          You can use the platform's built-in chat functionality to communicate with event organizers and vendors.
        </li>
        <li>
          <strong>What happens after an event is completed?</strong><br />
          After the event, you can rate the services provided by vendors, and event-related data is archived.
        </li>
        <li>
          <strong>What are reminders in EventPickl?</strong><br />
          Reminders are notifications that help you stay on track with your event planning tasks.
        </li>
        <li>
          <strong>What technologies are used in the EventPickl app?</strong><br />
          The specific technologies used in the app may vary, but it likely involves web development technologies, databases, and secure communication protocols.
        </li>
        <li>
          <strong>Is my data safe and secure on EventPickl?</strong><br />
          EventPickl is likely designed to prioritize the security and privacy of user data, but you should review the platform's privacy policy and terms of service for specific details.
        </li>
      </ol>
    </div>
</div>
<Footer footer="fullfooter" />

    </div>
  )
}

export default FAQ