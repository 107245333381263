import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField,Autocomplete } from '@mui/material';
export default function AddServiceModal(props) {
    const SiUnits = ['ml','L','kg','lbs','g','cm','m','inch',"pcs", 'boxes']
    const [newService,setNewService]=React.useState({})
  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Services
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add Services that you require for your event
          </DialogContentText>
          <TextField onChange={(e)=>setNewService({...newService,name:e.target.value})} fullWidth className="input" variant="filled" id="filled-basic" label="Service Name" />
          <TextField onChange={(e)=>setNewService({...newService,quantity:e.target.value})} fullWidth className="input" variant="filled" id="filled-basic" label="Service Quantity" />
          <TextField onChange={(e)=>setNewService({...newService,price:e.target.value})} fullWidth className="input" variant="filled" id="filled-basic" label="Service Price" />
          <div className="my-4 auto-complete-div">
            <Autocomplete
            onInputChange={(e,n)=>setNewService({...newService,unit:n})}
            id="free-solo-demo"
            freeSolo
            fullWidth
            options={SiUnits.map((option) => option)}
            renderInput={(params) => <TextField {...params} label="Select Unit" />}
          />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>{
            if(Object.keys(newService).length>0){
                props.addServiceFromModal(newService)
                props.setOpen(false)
            }
            }} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

{/* <TwoBDialog title="Delete Event" description="Are you sure you want to delete this event"
rightButton="Delete"
leftButton="Cancel"
open={open}
setOpen={setOpen}
handleSubmit={handleSubmit}
/> */}
